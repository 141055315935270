import 'isomorphic-fetch';
import 'typeface-source-sans-pro';

// function checkBrowserES6Support() {
//   try {
//     /* eslint-disable */
//     eval("var foo = () => 1");
//     /* eslint-enable */
//   }
//   catch (e) {
//     return false;
//   }
//   return true;
// }

async function main() {
  /* if (!checkBrowserES6Support()) {
    await import('babel-polyfill');
  } */
  if (!('flat' in Array.prototype)) {
    const flat = await import('array.prototype.flat');
    flat.default.shim();
  }
  await import('./main');
}

if (document !== undefined) {
  // client render
  main();
}
